<template>
    <div class="home_section2_container">
        <h1 class="home_section2_title wow animate__animated animate__aplayzFadeInUp  animate__fast" data-wow-offset="1">
            <slot name="homesection2title">
                알수록 놀라운 <br />
                음악의 힘
            </slot>
        </h1>

        <h2 class="home_section2_subtitle wow animate__animated animate__aplayzFadeInUp  animate__fast" data-wow-offset="5">
            <slot name="homesection2subtitle">
                음악은 감정을 움직이고, 분위기를 조성하며,
                <div class="home_section2_title_br"></div>
                경험과 기억을 형성합니다.
            </slot>
        </h2>

        <div class="wow animate__animated animate__aplayzFadeInUp  animate__fast" data-wow-offset="150">
            <ul class="home_section2_imggroup dp_flex">
                <li class="home_section2_img_size home_section2_img1">
                    <slot name="homesection2imgtextfirst">
                        <p class="home_section2_img_text first">
                            방문 고객 <strong class="home_section2_img_text_strong">77%</strong>는 매력적인 음악이 <br />
                            재생될 경우 긍정적 매장 방문 결정에 영향
                        </p>
                    </slot>
                </li>
                <li class="home_section2_img_size home_section2_img2">
                    <slot name="homesection2imgtextsecond">
                        <p class="home_section2_img_text">
                            매장에 어울리는 음악은 일반음악 대비 <br />
                            <strong class="home_section2_img_text_strong">37.1% </strong>매출 증가 효과
                        </p>
                    </slot>
                </li>
                <li class="home_section2_img_size home_section2_img3">
                    <slot name="homesection2imgtextthird">
                        <p class="home_section2_img_text">
                            공간 분위기와 어울리는 음악 재생시 <br />
                            체류시간 <strong class="home_section2_img_text_strong">35%</strong> 증가
                        </p>
                    </slot>
                </li>
            </ul>

            <p class="home_section2_paragraph">
                <slot name="homesection2paragraph">
                    &lt;출처&gt; <br />
                    Mood Media <br />
                    Milliman, R.E. Using Background Music to Affect the Behavior of Supermarket Shoppers. Journal of Marketing
                    <br />
                    Spotify 연구결과 매장음악이 매출에 끼치는 영향 분석
                </slot>
            </p>
        </div>
    </div>
</template>
<script>
import { WOW } from 'wowjs';

export default {
  name: 'HomeSection2',

  data () {
    return {
      wow: null
    };
  },

  mounted () {
    this.wow = new WOW({
      live: false
    });
    this.wow.init();
    this.wow.sync();
  },

  methods: {}
};
</script>
<style scoped src="@/assets/css/home/homesection2.css"></style>
